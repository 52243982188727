import React, {useEffect, useState} from "react";

import {
    exportFile,
    formatCurrency,
    getKeyValue,
    getProductDetailLink,
    isCampaignMarketSearch
} from "../../utils/Utils";
import {toDateString} from "../../utils/TimeUtil";


import {DashboardService} from "../../service/DashboardService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {Loading} from "../../utils/InlineComponents";
import {AppContext} from "../../HomePage";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ExportService} from "../../service/ExportService";
import {Dropdown} from "primereact/dropdown";
import {CampaignContext} from "../../App";
import {MultiSelect} from "primereact/multiselect";
import {Checkbox} from "primereact/checkbox";

const PriceComparison = (props) => {

    const {t, setError} = React.useContext(AppContext);
    const {campaigns, selectedCampaign, loadCampaigns} = React.useContext(CampaignContext);
    const [otherCampaigns, setOtherCampaigns] = useState([]);
    const [selectedOtherCampaigns, setSelectedOtherCampaigns] = useState([])
    const [showCheapestSellerInOtherCampaigns, setShowCheapestSellerInOtherCampaigns] = useState(false)
    const [showShippingPrices, setShowShippingPrices] = useState(false)
    const [showListPrices, setShowListPrices] = useState(false)

    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [competitorNames, setCompetitorNames] = useState([]);

    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);

    const [brand, setBrand] = useState(null);
    const [category, setCategory] = useState(null);
    const [product_name, setProductName] = useState("");
    const [ref_id, setRefId] = useState("");

    const [loading, setLoading] = useState(false);
    const [loadingExcelExport, setLoadingExcelExport] = useState(false);

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const dashboardService = new DashboardService();
    const exportService = new ExportService();

    useEffect ( ()=>{
        let otherCampaigns = campaigns.filter(item => item.id !== selectedCampaign.id).sort((a, b) => (a.name ? a.name.toLowerCase() : "").localeCompare((b.name ? b.name.toLowerCase() : ""), undefined, {sensitivity: 'base'}))
        setOtherCampaigns(otherCampaigns.map(otherCampaign => ({label: otherCampaign.name, value: otherCampaign.id})))
        search();
    },[selectedCampaign]);

    useEffect(() =>{
        setFilteredItems( items.filter(item=> (
            (product_name==="" || (item.name && item.name.toLowerCase().indexOf(product_name.toLowerCase()) !== -1)) &&
            (ref_id==="" || (item.ref_id && item.ref_id.toLowerCase().indexOf(ref_id.toLowerCase()) !== -1)) &&
            (brand==="" || brand == null || (item.brand && item.brand.toLowerCase().indexOf(brand.toLowerCase()) !== -1)) &&
            (category==="" || category == null || (item.category && item.category.toLowerCase().indexOf(category.toLowerCase()) !== -1))
        )));
    }, [items, product_name, brand, category, ref_id]);

    const search = () => {
        if (selectedCampaign) {
            setLoading(true);
            dashboardService.getPriceComparison(selectedCampaign.id, selectedOtherCampaigns, showCheapestSellerInOtherCampaigns, showListPrices).then(data=>{
                setItems(data.items);
                let distBrands = [...new Set(data.items.map(allProducts => allProducts.brand ? allProducts.brand : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                distBrands = distBrands.filter(brand => brand !== undefined && brand !== '')
                setBrands(distBrands.map(brand => ({label: brand, value: brand})))
                let distCategories = [...new Set(data.items.map(allProducts => allProducts.category ? allProducts.category : ""))].sort((a, b) => (a ? a : "").localeCompare((b ? b : ""), undefined, {sensitivity: 'base'}));
                distCategories = distCategories.filter(category => category !== undefined && category !== '')
                setCategories(distCategories.map(category => ({label: category, value: category})))
                setCompetitorNames(data.competitor_names);
                setLoading(false);
            }).catch(error =>{
                setError(error);
                setError(null);
                setLoading(false);
            });
        }
    };

    const onExport = () => {
        setLoadingExcelExport(true);
        exportService.exportPriceComparison(selectedCampaign.id, selectedOtherCampaigns, showCheapestSellerInOtherCampaigns, showShippingPrices, showListPrices).then((response) => {
            exportFile(response, selectedCampaign.name + "_" + t('dashboard.excel_price_comparison') + "_" + toDateString(new Date()) + ".xlsx");
            setLoadingExcelExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExcelExport(false);
        });
    };

    const nameTemplate = (rowData) => {
        return getProductDetailLink(rowData.id, rowData.name);
    };

    const minPriceTemplate = (rowData) => {
        return rowData.min_price ? <div className="p-grid p-justify-end">
            {formatCurrency(rowData.min_price, rowData.currency)}
        </div> : '';
    };

    const ownPriceTemplate = (rowData) => {
        return rowData.price ? <div className="p-grid p-justify-end">
            <a target="_blank" rel="noopener noreferrer" href={rowData.product_url} style={{color: rowData.min_competitor_price && rowData.min_price === rowData.price ? 'green' : 'black'}}>
                {formatCurrency(rowData.price, rowData.currency)}
            </a>
        </div> : '';
    };

    const dynamicColumnPriceTemplate = (rowData, props) => {
        return rowData[props.field] ? <div className="p-grid p-justify-end">
            <a target="_blank" rel="noopener noreferrer" href={rowData[props.field + '_url']} style={{color: rowData.min_competitor_name === props.field ? 'red' : 'black'}}>
                {formatCurrency(rowData[props.field], rowData.currency)}
            </a>
            {rowData[props.field + '_shipping_price'] && showShippingPrices && <span style={{color:'red'}}>{" + " + rowData[props.field + '_shipping_price']}</span>}
        </div> : '';
    };

    const dynamicColumns = () => {
        let columns = []
        columns.push(<Column key="ref_id" field="ref_id" header={t('product.ref_id')} headerStyle={{width: '150px'}} sortable/>);
        columns.push(<Column key="brand" field="brand" header={t('product.brand')} headerStyle={{width: '150px'}} sortable/>);
        columns.push(<Column key="category" field="category" header={t('product.category')} headerStyle={{width: '100px'}} sortable/>);
        columns.push(<Column key="name" field="name" body={nameTemplate} header={t('product.product_name')} headerStyle={{width: '300px'}} sortable/>);
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="position" field="position" header={t('product_detail.position')} headerStyle={{width: '50px', textAlign:"center"}} style={{textAlign:'center'}} sortable/>);}
        columns.push(<Column key="competitor_number" field="competitor_number" header={t('product_detail.seller_number')} headerStyle={{width: '70px', textAlign:"center"}} style={{textAlign:'center'}} sortable/>);
        columns.push(<Column key="min_price" field="min_price" body={minPriceTemplate} header={t('product.comp_min')} headerStyle={{width: '100px', textAlign:"right"}} sortable/>);
        columns.push(<Column key="min_competitor_name" field="min_competitor_name" header={t('product.comp_min_name')} headerStyle={{width: '120px', textAlign:"center"}} style={{textAlign:'center'}} sortable/>);
        {!isCampaignMarketSearch(selectedCampaign) && columns.push(<Column key="price" field="price" body={ownPriceTemplate} header={t('product_detail.own_price')} headerStyle={{width: '100px', textAlign:"right"}} sortable/>);}
        competitorNames.forEach((col,i) => {columns.push(<Column key={col} field={col} body={dynamicColumnPriceTemplate} header={col} headerStyle={{width: '100px',textAlign:"right"}} sortable/>)});
        return columns.map((col, i) => {return col});
    };

    const headerTemplate = <div className="p-grid p-fluid">
                <div className="p-col-3">
                    <span className="p-float-label">
                        <InputText id="product_name" value={product_name} onChange={(e) => {setProductName(getKeyValue(e).value)}}/>
                        <label htmlFor="product_name">{t('product.product_name')}</label>
                    </span>
                </div>
                <div className="p-col-3">
                    <span className="p-float-label">
                        <Dropdown name="brand" value={brand} options={brands} onChange={(e) => {setBrand(getKeyValue(e).value)}} filter showClear />
                        <label htmlFor="brand">{t('product.brand')}</label>
                    </span>
                </div>
                <div className="p-col-3">
                    <span className="p-float-label">
                        <Dropdown name="category" value={category} options={categories} onChange={(e) => {setCategory(getKeyValue(e).value)}} filter showClear />
                        <label htmlFor="category">{t('product.category')}</label>
                    </span>
                </div>
                <div className="p-col-3">
                    <span className="p-float-label">
                        <InputText id="ref_id" name="ref_id" value={ref_id} onChange={(e) => {setRefId(getKeyValue(e).value)}}/>
                    <label htmlFor="ref_id">{t('product.ref_id')}</label>
                </span>
                </div>
            </div>;

    return <React.Fragment>
        <div className="p-grid card">
            <div className="p-col-12">
                <h3>{t('dashboard.price_comparison')}</h3>
                <hr></hr>
            </div>

            {loading ?
            <div className="p-col-12" style={{textAlign:'center'}}>
                <Loading/>
            </div> :
            <div className="p-col-12 p-grid p-fluid">
                {otherCampaigns.length > 0 &&
                    <div className="p-col-2">
                        <span className="p-float-label">
                            <MultiSelect name="other_campaigns" value={selectedOtherCampaigns}
                                         options={otherCampaigns}
                                         onChange={(e) => {
                                             setSelectedOtherCampaigns(e.value)
                                         }}/>
                            <label htmlFor="other_campaigns">{t('campaign.other_campaigns')}</label>
                        </span>
                    </div>
                }
                <div className="p-col-2 p-mt-3">
                    <Checkbox inputId="show_cheapest_seller_in_other_campaigns" name="show_cheapest_seller_in_other_campaigns"
                              style={{marginLeft: "0.5em"}}
                              value={showCheapestSellerInOtherCampaigns}
                              checked={showCheapestSellerInOtherCampaigns}
                              onChange={(e)=> {setShowCheapestSellerInOtherCampaigns(getKeyValue(e).value);}}
                    />
                    <label htmlFor="show_cheapest_seller_in_other_campaigns" className="p-ml-2 p-mr-1">{t('campaign.show_cheapest_seller_in_other_campaigns')}</label>
                </div>
                <div className="p-col-2 p-mt-3">
                    <Checkbox inputId="show_list_prices" name="show_list_prices"
                              style={{marginLeft: "0.5em"}}
                              value={showListPrices}
                              checked={showListPrices}
                              onChange={(e)=> {setShowListPrices(getKeyValue(e).value);}}
                    />
                    <label htmlFor="show_list_prices" className="p-ml-2 p-mr-1">{t('campaign.show_list_prices')}</label>
                </div>
                <div className="p-col-2 p-mt-3">
                    <Checkbox inputId="show_also_shipping_prices" name="show_also_shipping_prices"
                              style={{marginLeft: "0.5em"}}
                              value={showShippingPrices}
                              checked={showShippingPrices}
                              onChange={(e)=> {setShowShippingPrices(getKeyValue(e).value);}}
                    />
                    <label htmlFor="show_also_shipping_prices" className="p-ml-2 p-mr-1">{t('campaign.show_also_shipping_prices')}</label>
                </div>
                <div className="p-col-2 p-mt-2">
                    <Button icon="pi pi-search" className="p-button-outlined p-button-info" label={t('actions.search')} onClick={search} loading={loadingExcelExport} />
                </div>
                <div className="p-col-2 p-mt-2">
                    <Button icon="pi pi-file-excel" className="p-button-outlined p-button-success" label={t('actions.excel')} onClick={onExport} loading={loadingExcelExport} />
                </div>
                <div className="p-col-12">
                    <DataTable value={filteredItems} scrollable scrollHeight={(window.innerHeight - 430)+"px"}
                               emptyMessage={t('actions.empty_message')}
                               paginator={true}
                               rows={rows}
                               first={first}
                               rowsPerPageOptions={[10, 20, 50, 100]}
                               onPage={(e) => {
                                   setFirst(e.first);
                                   setRows(e.rows);
                               }}
                               header={headerTemplate}>
                        {dynamicColumns()}
                    </DataTable>
                </div>
            </div>
            }
        </div>

    </React.Fragment>;
};
export default PriceComparison;
