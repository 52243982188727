import {AuthService} from "./AuthService";

export class CampaignService {
    url = `campaigns/`;
    url_upload = `product_upload/`;

    getCampaigns(detailed=false) {
        return AuthService.apiGet(this.url, {params: {detailed: detailed}}).then(res => res.data.campaigns);
    }

    countActiveAndInactiveProducts() {
        return AuthService.apiGet(this.url + 'count_active_and_inactive_products/').then(res => res.data.data);
    }

    countUnmatchedCompetitorSuggestions() {
        return AuthService.apiGet(this.url + 'count_unmatched_competitor_suggestions/').then(res => res.data.data);
    }

    getProductImportsOfCampaigns(campaign_ids) {
        return AuthService.apiGet(this.url + 'product_imports_of_campaigns/', {params: {campaign_ids: campaign_ids}}).then(res => res.data.data);
    }

    getProductsCreateOrUpdateScrapingRequestsOfCampaigns(campaign_ids) {
        return AuthService.apiGet(this.url + 'products_create_or_update_scraping_requests_of_campaigns/', {params: {campaign_ids: campaign_ids}}).then(res => res.data.data);
    }

    getPricesCampaignUpdateScrapingRequestsOfCampaigns(campaign_ids) {
        return AuthService.apiGet(this.url + 'prices_campaign_update_scraping_requests_of_campaigns/', {params: {campaign_ids: campaign_ids}}).then(res => res.data.data);
    }

    getHeadlessPricesCampaignUpdateScrapingRequestsOfCampaigns(campaign_ids) {
        return AuthService.apiGet(this.url + 'headless_prices_campaign_update_scraping_requests_of_campaigns/', {params: {campaign_ids: campaign_ids}}).then(res => res.data.data);
    }

    getCompetitorSuggestionScrapingRequestsOfCampaigns(campaign_ids) {
        return AuthService.apiGet(this.url + 'competitor_suggestion_scraping_requests_of_campaigns/', {params: {campaign_ids: campaign_ids}}).then(res => res.data.data);
    }

    static getCampaign() {
        return ((localStorage.getItem("selected_campaign") === undefined || localStorage.getItem("selected_campaign") === null) ? null : JSON.parse(localStorage.getItem("selected_campaign")));
    }

    addCampaign(name, use_sale_value, is_parent, parent_campaign, settings) {
        return AuthService.apiPost(this.url, {"campaign": {"name": name, "use_sale_value": use_sale_value, "is_parent": is_parent, "parent_campaign": parent_campaign, "settings": settings}}).then(res => res.data.campaign_created);
    }

    editCampaign(id, name, use_sale_value, is_parent, parent_campaign, settings){
        return AuthService.apiPut(this.url + id + "/", {"campaign": {"name": name, "use_sale_value": use_sale_value, "is_parent": is_parent, "parent_campaign": parent_campaign, "settings": settings}}).then(res => res.data.campaign_updated);
    }

    saveCampaignSettings(id, use_sale_value, settings){
        return AuthService.apiPut(this.url + id + "/", {"campaign": {"use_sale_value": use_sale_value, "settings": settings}}).then(res => res.data.campaign_updated);
    }

    retrieveCampaign(id) {
        return AuthService.apiGet(this.url + id).then(res => res.data.data);
    }

    deleteCampaign(id) {
        return AuthService.apiDelete(this.url + id).then(res => res.data.message);
    }

    addCampaignCompetitor(id, campaign_competitors){
        return AuthService.apiPut(this.url + id + "/campaign_competitors/add/", {"campaign_competitors": campaign_competitors}).then(res => res.data.campaign_updated);
    }

    copyCampaignCompetitors(fromCampaignId, toCampaignId){
        console.log(toCampaignId)
        return AuthService.apiPut(this.url + "campaign_competitors/copy/", {"from_campaign_id": fromCampaignId, "to_campaign_id": toCampaignId}).then(res => res.data.campaign_updated);
    }

    updateCampaignCompetitor(id, campaign_competitor){
        return AuthService.apiPut(this.url + id + "/campaign_competitors/update/", {"campaign_competitor": campaign_competitor}).then(res => res.data.campaign_updated);
    }

    deleteCampaignCompetitor(id, campaign_competitor){
        return AuthService.apiPut(this.url + id + "/campaign_competitors/delete/", {"campaign_competitor": campaign_competitor}).then(res => res.data.campaign_updated);
    }

    deleteCampaignCompetitorSuggestion(id, campaign_competitor_suggestion){
        return AuthService.apiPut(this.url + id + "/campaign_competitor_suggestions/delete/", {"campaign_competitor_suggestion": campaign_competitor_suggestion}).then(res => res.data.campaign_updated);
    }

    savePricingStrategy(id, pricing_strategy){
        return AuthService.apiPut(this.url + id + "/pricing_strategy/", {"pricing_strategy": pricing_strategy}).then(res => res.data.message);
    }

    applyPriceSuggestions(id){
        return AuthService.apiPut(this.url + id + "/pricing_suggestions/apply/").then(res => res.data.message);
    }

    scrapingJobHours(id){
        return AuthService.apiGet(this.url + id + "/scraping_job_hours/").then(res => res.data.data);

    }

    updateScrapingJobHours(id, job_hours){
        return AuthService.apiPut(this.url + id + "/update_scraping_job_hours/", {"job_hours": job_hours}).then(res => res.data.message);

    }

    scrapingJobSettings(){
        return AuthService.apiGet(this.url + "scraping_job_settings/").then(res => res.data.data);
    }

    getCampaignCompetitors(id){
        return AuthService.apiGet(this.url + id + "/campaign_competitors/").then(res => res.data.data);
    }

    getCampaignCompetitorSuggestions(id){
        return AuthService.apiGet(this.url + id + "/campaign_competitor_suggestions/").then(res => res.data.data);
    }

    getCampaignSpecialFields(id){
        return AuthService.apiGet(this.url + id + "/special_fields/").then(res => res.data.data);
    }

    saveBanner(id, banner){
        return AuthService.apiPut(this.url + id + "/banner/", {"banner": banner}).then(res => res.data.message);
    }

    copyDefaultCampaignSettings(user_id){
        return AuthService.apiPut(this.url + "copy_default_campaign_settings/", {"user_id": user_id}).then(res => res.data.message);
    }
}
