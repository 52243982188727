import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Message} from 'primereact/message';
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import {getKeyValue, isAdmin, user} from "../../utils/Utils";
import {usage_types} from "../../utils/Constants";
import {AppContext} from "../../HomePage";
import {CampaignContext} from "../../App";

const CampaignEdit = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const {selectedCampaign} = React.useContext(CampaignContext);

    const [campaign, setCampaign] = useState(selectedCampaign ?? {name:""});
    const [formErrors, setFormErrors] = useState({name:""});

    useEffect(() =>{
        if (campaign && campaign.settings == null) {
            campaign.settings = {usage_type : user().usage_type, variant_import : user().settings?.variant_import ?? false}
            setCampaign({...campaign});
        }
        let parent_campaigns = props.campaigns ? props.campaigns.filter(c => c.id === campaign?.parent_campaign_id) : []
        if (parent_campaigns.length === 1) {
            campaign.parent_campaign = parent_campaigns[0]
            setCampaign({...campaign});
        }

    }, []);

    const save = () => {
        props.save(campaign)
    };

    const onChange = (e) =>{
        const { key, value } = getKeyValue(e);

        if (key.includes('.')){
            let p = campaign[key.split('.')[0]] ?? {};
            p[key.split('.')[1]] = value;
            setCampaign({...campaign, [key.split('.')[0]] : p});
        }
        else{
            setFormErrors({...formErrors, [key]: checkError(key, value)});
            setCampaign({...campaign, [key] : value});
        }
    };

    const errorClass = (key) => {
        return formErrors[key] && formErrors[key].length > 0 ? "p-error" : null
    };

    const checkError = (key, value) => {
        let errorText = "";
        if (key === "name") {
            errorText =  value.length > 0 ? "" : t('validations.invalid_name');
        }
        return errorText;
    }

    return (
        <React.Fragment>
            <h3>{props.page === "add" ? t('campaign.add_campaign') : t('campaign.edit_campaign')}</h3>
            <div className="p-grid alignCenter p-fluid">
                <div className="p-col-12">
                    <span className="p-float-label">
                        <InputText id="name" name="name" value={campaign.name} onChange={onChange} className={errorClass("name")} autoFocus={true}/>
                        <label htmlFor="name">{t('campaign.name')}</label>
                    </span>
                    {errorClass("name") && (<Message severity="error" text={formErrors.name}/>)}
                </div>
                <div className="p-col-12 p-xl-12">
                    <span className="p-float-label">
                        <Dropdown id="usage_type" name="settings.usage_type" value={campaign.settings?.usage_type} options={usage_types(t)} onChange={onChange} />
                        <label htmlFor="usage_type">{t('campaign.usage_type')}</label>
                    </span>
                </div>
                {isAdmin() && props.page === "edit" &&
                    <div className="p-col-12 p-xl-12">
                        <span className="p-float-label">
                            <Checkbox inputId="is_parent" name="is_parent" value={{}} checked={campaign.is_parent} onChange={onChange}/>
                            <label htmlFor="is_parent" style={{marginLeft:"1em", marginRight:"1em"}}>{t('campaign.is_parent_campaign')}</label>
                        </span>
                    </div>
                }
                {isAdmin() && props.page === "edit" &&
                    <div className="p-col-12 p-xl-12">
                        <span className="p-float-label">
                            <Dropdown showClear className="p-mr-1" id="parent_campaign" name="parent_campaign"
                                      value={campaign.parent_campaign}
                                      options={props.campaigns ? props.campaigns.filter(c => c.is_parent && c.id !== selectedCampaign?.id) : []}
                                      onChange={onChange} optionLabel="name"/>
                            <label htmlFor="parent_campaign">{t('campaign.parent_campaign')}</label>
                        </span>
                    </div>
                }
                {user().settings?.allow_currency_entry && <div className="p-col-12 p-xl-12">
                    <span className="p-float-label">
                        <InputText id="currency_rate" name="settings.currency_rate" value={campaign.settings?.currency_rate} onChange={onChange} />
                        <label htmlFor="currency_rate">{t('campaign.currency_rate')}</label>
                    </span>
                </div>}

                <div className="p-col-6">
                    <Button label={t('actions.cancel')} icon="pi pi-angle-double-left" className="p-button-outlined p-button-warning" onClick={() => props.returnPageList()}/>
                </div>
                <div className="p-col-6">
                    <Button label={t('actions.save')} icon="pi pi-save" className="p-button-outlined p-button-success" onClick={save}/>
                </div>
            </div>
        </React.Fragment>
    );
};
export default CampaignEdit
