import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";


import {exportFile, formValid, getKeyValue, isAdmin, isCampaignDropShipping, isCampaignSeller, isPreventSelect, user} from "../../utils/Utils";
import {IndexTemplate, Loading} from "../../utils/InlineComponents";

import ScrapeStatus from "../Product/ScrapeStatus";
import CampaignUrl from "./CampaignUrl";
import CampaignCompetitor from "./CampaignCompetitor";
import CampaignReprice from "./CampaignReprice";
import {CampaignService} from "../../service/CampaignService";
import {ScraperService} from "../../service/ScraperService";
import {Sidebar} from "primereact/sidebar";
import {SplitButton} from "primereact/splitbutton";
import CampaignSettings from "./CampaignSettings";
import {AppContext} from "../../HomePage";
import CampaignEdit from "./CampaignEdit";
import {usage_types} from "../../utils/Constants";
import {CampaignContext} from "../../App";
import {Skeleton} from "primereact/skeleton";
import {toDateString} from "../../utils/TimeUtil";
import {ExportService} from "../../service/ExportService";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {ExportAsyncService} from "../../service/ExportAsyncService";

const Campaign = (props) => {

    const {t, toast, setError} = React.useContext(AppContext);
    const {campaigns, selectedCampaign, selectCampaign, loadCampaigns, updateCampaigns, toggleDownloadReports} = React.useContext(CampaignContext);

    const pages = {
        list: 'list',
        add: 'add',
        edit: 'edit'
    };

    const [page, setPage] = useState(pages.list);
    const [formErrors, setFormErrors] = useState({});

    const [showRepricing, setShowRepricing] = useState(false);
    const [showMyUrls, setShowMyUrls] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showCompetitorUrls, setShowCompetitorUrls] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [campaignNameToDelete, setCampaignNameToDelete] = useState("");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [rowData, setRowData] = useState(null);

    const campaignService = new CampaignService();
    const scraperService = new ScraperService();
    const exportService = new ExportService();
    const exportAsyncService = new ExportAsyncService();

    useEffect(() =>{
        if (selectCampaign)
            loadCampaigns(true);
        return () => {
            if(toast.current)
                toast.current.clear();
        }
    }, []);


    const add = () => {
        selectCampaign(null).then(()=> {
            setFormErrors({});
            setPage(pages.add);
        });
    };

    const edit = (rowData) => {
        selectCampaign(rowData.id).then(()=> {
            setFormErrors({});
            setPage(pages.edit);
        });
    };

    const save = (campaign) => {
        checkErrors(campaign).then(formErrors=> {
            if (formValid(formErrors)) {
                if (page === pages.add)
                {
                    campaignService.addCampaign(campaign.name, campaign.use_sale_value, campaign.is_parent, campaign.parent_campaign, campaign.settings).then(savedCampaign => {
                        savedCampaign.scraping_requests = [];
                        updateCampaignInList(savedCampaign);
                        setPage(pages.list);
                        toast.current.show({severity: 'success', summary: t('campaign.add_campaign'), detail: t('message_detail.successful') + " "});
                    }).catch(error =>{
                        setError(error);
                        setError(null);
                    });
                }
                else if (page === pages.edit)
                {
                    campaignService.editCampaign(campaign.id, campaign.name, campaign.use_sale_value, campaign.is_parent, campaign.parent_campaign, campaign.settings).then(() => {
                        updateCampaignInList(campaign);
                        setPage(pages.list);
                        toast.current.show({severity: 'success', summary: t('campaign.edit_campaign'), detail: t('message_detail.successful')});
                    }).catch(error =>{
                        setError(error);
                        setError(null);
                    });
                }

            }
            else
                setFormErrors(formErrors);
        });

    };

    const updateCampaignInList = (campaign) => {
        const index = campaigns.findIndex(c => c.id === campaign.id);
        if (index === -1)
            campaigns.unshift(campaign);
        else
            campaigns[index] = campaign;
        updateCampaigns([...campaigns]);
    };

    const deleteCampaign = (campaign) => {
        if(campaign.name === campaignNameToDelete)
            campaignService.deleteCampaign(campaign.id).then(() => {
                let filteredCampaigns = campaigns.filter(c => c.id !== campaign.id);
                updateCampaigns([...filteredCampaigns]);
                toast.current.show({severity: 'success', summary: t('campaign.delete_campaign'), detail: t('message_detail.successful')});
            }).catch(error =>{
                setError(error);
                setError(null);
            });
        else
            toast.current.show({severity: 'warn', summary: t('campaign.delete_campaign_name_wrong'), detail: t('message_detail.failed')});
    }

    const getShippingPrices = (rowData) => {
        scraperService.getShippingPrices({
            "spider_name": "prices_campaign_update",
            "type": "shipping_price",
            "campaign_id": rowData.id
        }).then(() => {
            toast.current.show({severity: 'success', summary: t('actions.get_shipping_price'), detail: t('message_detail.successful')});
        }).catch(error =>{
            setError(error);
            setError(null);
        });
    };

    const checkError = (key, value) => {
        let errorText = "";
        if (key === "name") {
            errorText =  value.length > 0 ? "" : t('validations.invalid_name');
        }else if (key === "use_sale_value") {
            errorText =  value !== null && value !== undefined ? "" : t('validations.invalid_value');
        }
        return errorText;
    }

    const checkErrors = async (campaign) => {
        let errors = { ...formErrors };
        Object.entries(campaign).forEach(([key, value]) => {
            errors[key] = checkError(key, value);
        });
        return errors;
    };

    const changeCampaign = (e) => {
        let target = e.originalEvent.target;
        if(!isPreventSelect(target))
            if (e.data)
                selectCampaign(e.data.id).then(() => {
                    window.location = "#/product";
                });
    };

    const nameTemplate = (rowData) => <div data-tour="step3">
        <span className="p-column-title">{t('campaign.name')}</span>
        {rowData.name}
    </div>;
    const usageTypeTemplate = (rowData) => {
        const usageTypes = usage_types(t)
        const usageType = rowData.settings?.usage_type ? rowData.settings.usage_type : user().usage_type
        const filteredUsageType = usageTypes.filter((item) => item.value === usageType)
        return <div>
            <span className="p-column-title">{t('campaign.usage_type')}</span>
            {filteredUsageType[0].label}
        </div>;
    };

    const productCountTemplate = (rowData) => {
        return !rowData.product_count && rowData.product_count !== 0 ? <Skeleton /> : <div>
            <span className="p-column-title">{t('campaign.product_count')}</span>
            {rowData.product_count}
        </div>
    };
    const inactiveProductCountTemplate = (rowData) => {
        return !rowData.inactive_product_count && rowData.inactive_product_count !== 0 ? <Skeleton /> : <div>
            <span className="p-column-title">{t('campaign.inactive_product_count')}</span>
            {rowData.inactive_product_count}
        </div>
    };
    const suggestionCountTemplate = (rowData) => {
        return !rowData.unmatched_competitor_suggestions_count && rowData.unmatched_competitor_suggestions_count !== 0 ? <Skeleton /> : <React.Fragment>
            <span className="p-column-title">{t('campaign.product_unmatched_competitor_suggestions_count')}</span>
            {rowData.unmatched_competitor_suggestions_count}
        </React.Fragment>
    };

    const exportDetailedProductList = (campaign) => {
        setLoadingExport(true);
        exportAsyncService.exportDetailedProductList(campaign.id, null, null).then((response) => {
            setLoadingExport(false);
            toggleDownloadReports(true);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExport(false);
        });
        // exportService.exportDetailedProductList(campaign.id, null, null).then((response) => {
        //     exportFile(response, campaign.name + "_" + t('dashboard.excel_product_list') + "_" + toDateString(new Date) + ".xlsx");
        //     setLoadingExport(false);
        // }).catch(error =>{
        //     setError(error);
        //     setError(null);
        //     setLoadingExport(false);
        // });
    };

    const exportCompetitorList = (campaign) => {
        setLoadingExport(true);
        exportService.exportCompetitorPriceList(campaign.id, new Date()).then((response) => {
            exportFile(response, campaign.name + "_" + t('dashboard.excel_competitor_list') + "_" + toDateString(new Date) + ".xlsx");
            setLoadingExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExport(false);
        });
    };

    const exportProductReviews = (campaign) => {
        setLoadingExport(true);
        exportService.exportProductReviews(campaign.id).then((response) => {
            exportFile(response, campaign.name + "_" + t('dashboard.csv_product_reviews') + "_" + toDateString(new Date) + ".xlsx");
            setLoadingExport(false);
        }).catch(error =>{
            setError(error);
            setError(null);
            setLoadingExport(false);
        });
    };


    const reportsTemplate = (rowData) => {

        return <span>
            <Button tooltip={t('dashboard.excel_product_list')} tooltipOptions={{position: 'bottom'}} icon="pi pi-file-excel"
                    onClick={() => {exportDetailedProductList(rowData)}} loading={loadingExport} className="p-button-outlined p-button-success p-mr-1"/>
            <Button tooltip={t('dashboard.excel_competitor_list')} tooltipOptions={{position: 'bottom'}} icon="pi pi-file-excel"
                    onClick={() => {exportCompetitorList(rowData)}} loading={loadingExport} className="p-button-outlined p-button-info p-mr-1"/>
            { rowData?.settings?.special_fields?.some(sf=> sf.name === "reviews" && sf.selected) &&
                <Button tooltip={t('dashboard.csv_product_reviews')} tooltipOptions={{position: 'bottom'}} icon="pi pi-comments"
                        onClick={() => {exportProductReviews(rowData)}} loading={loadingExport} className="p-button-outlined p-button-warning"/>
            }
        </span>
    }


    const statusTemplate = (rowData) => {
        //campaign bazlı
        const newPriceRequest = {
            type: "prices_campaign_update",
            campaign_id: rowData.id};

        return <div data-tour="step6">
            <span className="p-column-title">{t('table_headers.scraping_status')}</span>
            <ScrapeStatus type="price" scraping_request={rowData.prices_campaign_update_scraping_request}
                             campaign_id={rowData.id}
                             product_id={null}
                             competitor_url={null}
                          newRequest={newPriceRequest}
                          newRequestCompleted={() => {loadCampaigns(true)}} />
            {rowData.headless_prices_campaign_update_scraping_request && isAdmin() &&
                <span style={{marginLeft:'0.5em'}}><ScrapeStatus type="price" scraping_request={rowData.headless_prices_campaign_update_scraping_request}
                             campaign_id={rowData.id}
                             product_id={null}
                             competitor_url={null}
                          newRequest={newPriceRequest}
                          newRequestCompleted={() => {loadCampaigns(true)}} />
                </span>}
        </div>;
    };
    const detailTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{t('campaign.urls')}</span>
            <Button icon="pi pi-list" className="p-button-outlined p-button-rounded p-button-info p-mr-1" tooltipOptions={{position: 'bottom'}} tooltip={t('campaign.my_urls')} data-tour="step4" onClick={() => {selectCampaign(rowData.id).then(()=> {setShowMyUrls( true);});}} />
            <Button icon="pi pi-sitemap" className="p-button-outlined p-button-rounded p-button-warning" tooltipOptions={{position: 'bottom'}} tooltip={t('campaign.competitor_urls')} data-tour="step5" onClick={() => {selectCampaign(rowData.id).then(()=> {setShowCompetitorUrls( true);});}} />
        </div>;
    };

    const actions  = (rowData) => {
        let actionsList = [];

        if (isCampaignSeller(rowData) || isCampaignDropShipping(rowData))
            actionsList.push({
                label: t('product_detail.repricing'),
                icon: 'pi pi-compass',
                command:(e) => {selectCampaign(rowData.id).then(()=> {setShowRepricing( true);});}
            });
        actionsList.push({
            label: t('actions.remove'),
            icon: 'pi pi-trash',
            style: {color: 'red'},
            command:(e) => {
                setShowDeleteConfirm(true);
                setRowData(rowData);}
        });

        if (isAdmin()){
            actionsList.push({
                label: t('actions.get_shipping_price'),
                icon: 'pi pi-shopping-cart',
                command:(e) => {getShippingPrices(rowData)}
            });

            actionsList.push({
                label: t('campaign.settings'),
                icon: 'pi pi-cog',
                command:(e) => {selectCampaign(rowData.id).then(()=> {setShowSettings( true);});}
            });
        }
        return actionsList;
    };

    const actionTemplate = (rowData) => {
        return <div>
            <span className="p-column-title">{t('table_headers.action')}</span>
            <SplitButton label={t('actions.edit')} icon="pi pi-pencil" className="p-button-outlined p-button-info preventSelect" onClick={() => edit(rowData)} menuClassName="preventSelect" model={actions(rowData)}/>
        </div>;
    };


    const footer = (<React.Fragment>
        {t('campaign.footer',
            {
                active_count: campaigns ? campaigns.reduce((a, c) => a += (c.product_count ?? 0), 0) : 0,
                inactive_count: campaigns ? campaigns.reduce((a, c) => a += (c.inactive_product_count ?? 0), 0) : 0
            })}
    </React.Fragment>);

    const returnPageList = () => {
        setPage(pages.list)
    }

    return (
        <React.Fragment>
            <div className="card">
                <h5><Button icon="pi pi-plus" data-tour="step7"
                            className="p-button-outlined p-button-success"
                            onClick={() => add()} label={t('campaign.add_campaign')}/></h5>
                {/*{loading && <div className="p-col-12" style={{textAlign:'center'}}>*/}
                {/*    <Loading/>*/}
                {/*</div>}*/}
                {/*{!loading &&*/}
                <div data-tour="step2">
                    <DataTable value={campaigns}
                               responsive={true}
                               selectionMode="single"
                               selection={selectedCampaign && campaigns.find(c=> c.id === selectedCampaign.id) ? campaigns.find(c=> c.id === selectedCampaign.id) : null}
                               onRowClick={changeCampaign}
                               sortField="name" sortOrder={1}
                               footer={footer}
                               emptyMessage={t('campaign.no_campaign')}>
                        <Column body={IndexTemplate} headerStyle={{width:'3em'}} />
                        <Column sortField="name" body={nameTemplate} header={t('campaign.name')}/>
                        <Column body={usageTypeTemplate} header={t('campaign.usage_type')}/>
                        <Column body={productCountTemplate} header={t('campaign.product_count')} className="p-text-right" />
                        <Column body={inactiveProductCountTemplate} header={t('campaign.inactive_product_count')} className="p-text-right"/>
                        <Column body={suggestionCountTemplate} header={t('campaign.product_unmatched_competitor_suggestions_count')} className="p-text-right"/>
                        <Column body={reportsTemplate} className="preventSelect p-text-right" header={t('dashboard.excel_reports')}/>
                        <Column body={statusTemplate} className="preventSelect p-text-right" header={t('table_headers.scraping_status')} headerStyle={{width:'7em'}} />
                        <Column body={detailTemplate} className="preventSelect p-text-center" header={t('campaign.urls')} headerStyle={{width:'7.3em'}} />
                        <Column body={actionTemplate} className="preventSelect p-text-center" header={t('table_headers.action')} headerStyle={{width:'11em'}}/>
                    </DataTable>
                </div>
                {/*}*/}

            </div>

            {showDeleteConfirm &&
            <Dialog header={t('dialogs.confirm_campaign_delete')} visible={showDeleteConfirm} modal={true} width="600px" onHide={() => {
                setShowDeleteConfirm(false);
                setCampaignNameToDelete("");
            }}>
                <div className="p-flex p-flex-column" style={{flex: '1'}}>
                    <div className="p-text-center">
                        <i className="pi pi-exclamation-triangle" style={{fontSize: '3rem'}}/>

                        <h5>{t('dialogs.confirm_campaign_delete_msg0')}<b>{(rowData ? rowData.name : "")}</b></h5>

                        <p>{t('dialogs.confirm_campaign_delete_msg1')}</p>

                        <p>{t('dialogs.confirm_campaign_delete_msg2')}</p>
                        <br/>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <InputText id="campaignNameToDelete" name="campaignNameToDelete" value={campaignNameToDelete} onChange={(e) => {setCampaignNameToDelete(getKeyValue(e).value)}}/>
                        </div>
                        <div className="p-col-6">
                            <Button label={t('yes_no.yes')} icon="pi pi-check" className="p-button-success" onClick={() => {
                                deleteCampaign(rowData);
                                setShowDeleteConfirm(false);
                                setCampaignNameToDelete("");
                            }} />
                        </div>
                        <div className="p-col-6">
                            <Button label={t('yes_no.no')} icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                setShowDeleteConfirm(false);
                                setCampaignNameToDelete("");
                            }} />
                        </div>
                    </div>
                </div>
            </Dialog>}

            <Sidebar visible={showMyUrls} style={{width:'50em', overflow: "scroll"}} position="right" onHide={() => {setShowMyUrls(false)}}>
                <CampaignUrl updateCampaignList={(campaign) => {
                    updateCampaignInList(campaign);
                }}/>
            </Sidebar>

            <Sidebar visible={showCompetitorUrls} style={{width:'60em', overflow: "scroll"}} position="right" onHide={() => {setShowCompetitorUrls(false)}}>
                <CampaignCompetitor updateCampaignList={(campaign) => {
                    updateCampaignInList(campaign);
                }} />
            </Sidebar>

            <Sidebar visible={showRepricing} style={{width:'40em', overflow: "scroll"}} position="right" onHide={() => {setShowRepricing(false)}}>
                <CampaignReprice updateCampaignList={(campaign) => {
                    updateCampaignInList(campaign);
                }} />
            </Sidebar>

            <Sidebar visible={showSettings} style={{width:'60em', overflow: "scroll"}} position="right" onHide={() => {setShowSettings(false)}}>
                <CampaignSettings />
            </Sidebar>

            <Sidebar showCloseIcon={false} visible={page === pages.add || page === pages.edit} style={{width:'30em', overflow: "scroll"}} position="right" onHide={() => {setPage(pages.list)}}>
                <CampaignEdit campaigns={campaigns} page={page} save={save} returnPageList={returnPageList}/>
            </Sidebar>

        </React.Fragment>
    );
};
export default Campaign
